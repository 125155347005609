
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@theme: default;

// ------- Base Scaffolding Variables -----------
@line-height-base: 1.5715;
@border-radius-base: 2px;
@border-radius-sm: 2px;
@component-background: #fff;

// -------- Colors -----------
@white: #fff;
@black: #19191C;
@primary-color: #FF5C00;
@primary-color-hover: color(colorPalette('@{primary-color}', 5));
@primary-color-active: color(colorPalette('@{primary-color}', 7));
@primary-color-outline: fade(@primary-color, @outline-fade);

// Color used by default to control hover and active backgrounds and for
// alert info backgrounds.
@primary-1: color(colorPalette('@{primary-color}', 1)); // replace tint(@primary-color, 90%)
@primary-2: color(colorPalette('@{primary-color}', 2)); // replace tint(@primary-color, 80%)
@primary-3: color(colorPalette('@{primary-color}', 3)); // unused
@primary-4: color(colorPalette('@{primary-color}', 4)); // unused
@primary-5: color(
  colorPalette('@{primary-color}', 5)
); // color used to control the text color in many active and hover states, replace tint(@primary-color, 20%)
@primary-6: @primary-color; // color used to control the text color of active buttons, don't use, use @primary-color
@primary-7: color(colorPalette('@{primary-color}', 7)); // replace shade(@primary-color, 5%)
@primary-8: color(colorPalette('@{primary-color}', 8)); // unused
@primary-9: color(colorPalette('@{primary-color}', 9)); // unused
@primary-10: color(colorPalette('@{primary-color}', 10)); // unused

@heading-color: fade(@black, 100%);
@text-color: fade(@black, 60%);
@text-color-secondary: fade(@black, 45%);

// Outline
@outline-blur-size: 0;
@outline-width: 2px;
@outline-color: @primary-color; // No use anymore
@outline-fade: 20%;

@background-color-light: hsv(0, 0, 98%); // background of header and selected item
@background-color-base: hsv(0, 0, 96%); // Default grey background color

// >>> Info
@info-color: @primary-color;
@info-color-deprecated-bg: color(colorPalette('@{info-color}', 1));
@info-color-deprecated-border: color(colorPalette('@{info-color}', 3));

// >>> Success
@success-color: @green-6;
@success-color-hover: color(colorPalette('@{success-color}', 5));
@success-color-active: color(colorPalette('@{success-color}', 7));
@success-color-outline: fade(@success-color, @outline-fade);
@success-color-deprecated-bg: color(colorPalette('@{success-color}', 1));
@success-color-deprecated-border: color(colorPalette('@{success-color}', 3));

// >>> Warning
@warning-color: @gold-6;
@warning-color-hover: color(colorPalette('@{warning-color}', 5));
@warning-color-active: color(colorPalette('@{warning-color}', 7));
@warning-color-outline: fade(@warning-color, @outline-fade);
@warning-color-deprecated-bg: color(colorPalette('@{warning-color}', 1));
@warning-color-deprecated-border: color(colorPalette('@{warning-color}', 3));

// >>> Error
@error-color: @red-5;
@error-color-hover: color(colorPalette('@{error-color}', 5));
@error-color-active: color(colorPalette('@{error-color}', 7));
@error-color-outline: fade(@error-color, @outline-fade);
@error-color-deprecated-bg: color(colorPalette('@{error-color}', 1));
@error-color-deprecated-border: color(colorPalette('@{error-color}', 3));

// -------- Typography -----------
@font-family: 'Poppins', sans-serif;

// Base font size
@font-size-base: 14px;
@font-size-lg: @font-size-base + 2px;
@font-size-sm: 12px;

// Heading styles
@heading-1-size: ceil(@font-size-base * 2.71);
@heading-2-size: ceil(@font-size-base * 1.40);
@heading-3-size: ceil(@font-size-base * 1.71);
@heading-4-size: ceil(@font-size-base * 1.42);
@heading-5-size: ceil(@font-size-base * 1.14);

// Paragraph styles
@typography-title-font-weight: 500;
@typography-title-margin-top: 1.2em;
@typography-title-margin-bottom: 0.5em;

// -------- Layout -----------
// vertical paddings
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical margins
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

//
@menu-inline-toplevel-item-height: 40px;
@menu-item-height: 40px;
@menu-item-group-height: @line-height-base;
@menu-collapsed-width: 80px;
@menu-bg: @component-background;
@menu-popup-bg: @component-background;
@menu-item-color: @text-color;
@menu-inline-submenu-bg: @background-color-light;
@menu-highlight-color: @primary-color;
@menu-highlight-danger-color: @error-color;
@menu-item-active-bg: @primary-1;
@menu-item-active-danger-bg: @red-1;
@menu-item-active-border-width: 3px;
@menu-item-group-title-color: @text-color-secondary;
@menu-item-vertical-margin: 4px;
@menu-item-font-size: @font-size-base;
@menu-item-boundary-margin: 8px;
@menu-item-padding-horizontal: 20px;
@menu-item-padding: 0 @menu-item-padding-horizontal;
@menu-horizontal-line-height: 46px;
@menu-icon-margin-right: 10px;
@menu-icon-size: @menu-item-font-size;
@menu-icon-size-lg: @font-size-lg;
@menu-item-group-title-font-size: @menu-item-font-size;

// PageHeader
// ---
@page-header-padding: @padding-lg;
@page-header-padding-vertical: @padding-md;
@page-header-padding-breadcrumb: @padding-xs;
@page-header-content-padding-vertical: @padding-sm;
@page-header-back-color: #000;
@page-header-ghost-bg: inherit;
@page-header-heading-title: @heading-4-size;
@page-header-heading-sub-title: 14px;
@page-header-tabs-tab-font-size: 16px;

// Breadcrumb
// ---
@breadcrumb-base-color: @text-color-secondary;
@breadcrumb-last-item-color: @text-color;
@breadcrumb-font-size: @font-size-sm;
@breadcrumb-icon-font-size: @font-size-base;
@breadcrumb-link-color: @primary-color;
@breadcrumb-link-color-hover: @primary-color-hover;
@breadcrumb-separator-color: @primary-color;
@breadcrumb-separator-margin: 0 @padding-xss;
