:root {
  // Global
  --global-header-height: 78px;

  // Z-index
  --z-index-0: 0;
  --z-index-1: 10;
  --z-index-2: 20;
  --z-index-3: 30;
  --z-index-4: 40;
  --z-index-5: 50;

  // Colors
  --primary-10: #FFF5EE;
  --primary-20: #FEE3D0;
  --primary-30: #FDC7A2;
  --primary-40: #FF9559;
  --primary-50: #FF7527;
  --primary-60: #FF5C00;

  --neutral-10: #FFFFFF;
  --neutral-20: #F8F9FD;
  --neutral-30: #E4E4E4;
  --neutral-40: #C2C2C2;
  --neutral-50: #9C9C9C;
  --neutral-60: #828487;
  --neutral-100: #19191C;
}
