/* You can add global styles to this file, and also import other style files */

// Fix for SVG icons imported from the menuize design system
svg[data-icon*="menuize:"] {
  fill: none;
}

// Custom class for the button component
.mze-button {
  border-radius: 25px;
  height: 48px;
  font-weight: 600;
}
